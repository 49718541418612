import { Injectable } from '@angular/core';
import { v4 as uuidv4 } from 'uuid';
@Injectable({ providedIn: 'root' })
export class StringUtilService {
  isValidCode(code: string) {
    //Chỉ cho phép ký tự A-Z, a-z, số và dấu . và dấu -
    var regex = /^[A-Za-z0-9.-]+$/
    return regex.test(code);
  }
  isValidUniCode(code: string) {
    //Chỉ cho phép ký tự A-Z, a-z, số và dấu . và dấu -
    var regex = /^[a-zA-Z\u00C0-\u017F]+,\s[a-zA-Z\u00C0-\u017F]+$/
    return regex.test(code);
  }
  isValidEmail(email: string) {
    //Chỉ cho phép ký tự A-Z, a-z, số và dấu . và dấu -
    var regex = /^[A-Za-z0-9.@-]+$/
    return regex.test(email);
  }
  padLeft(str: string, max: number): string {
    var str = str.toString();
    return str.length < max ? this.padLeft("0" + str, max) : str;
  }
  //UUID
  getUuid(){
    return uuidv4();
  }
  convertVNKhongDau(str: string, toUpperCase = false) {
    if (!str || typeof(str)!=='string'){
      return '';
    }
    str = str.toLowerCase();
    str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
    str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
    str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
    str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
    str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
    str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
    str = str.replace(/đ/g, "d");
    // Some system encode vietnamese combining accent as individual utf-8 characters
    str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, ""); // Huyền sắc hỏi ngã nặng
    str = str.replace(/\u02C6|\u0306|\u031B/g, ""); // Â, Ê, Ă, Ơ, Ư

    return toUpperCase ? str.toUpperCase() : str;
}
}
